import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import hero from "../../assets/hero6.png";
import { FaCode, FaTools, FaArrowRight } from "react-icons/fa";
import { GiTrophy, GiMountainClimbing, GiGingerbreadMan } from "react-icons/gi";
import { PiFlowArrowBold } from "react-icons/pi";
import { MdQueryStats } from "react-icons/md";
import EmbeddedForm from "./EmbeddedForm";

import Editor from "@monaco-editor/react";
//import AdventNotice from "./AdventNotice";
// import FeaturedLinks from "./Featured";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Create and append the script
    const script = document.createElement("script");
    script.src = "https://widget.senja.io/js/iframeResizer.min.js";
    script.async = true;

    script.onload = () => {
      // Once the script is loaded, initialize the iFrame resizer
      if (window.iFrameResize) {
        window.iFrameResize(
          { log: false, checkOrigin: false },
          "#wall-of-love-2yNE8N"
        );
      }
    };

    document.body.appendChild(script);

    // Cleanup function
    return () => {
      document.body.removeChild(script);
      // Remove any iframe resizer instances if they exist
      const iframe = document.getElementById("wall-of-love-2yNE8N");
      if (iframe && iframe.iFrameResizer) {
        iframe.iFrameResizer.removeListeners();
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-zinc-900 text-white">
      <Helmet>
        <title>
          Learn Salesforce Development | Interactive Apex Coding Practice for
          Beginners
        </title>
        <meta
          name="description"
          content="Start learning Salesforce development from scratch with hands-on Apex coding practice. Interactive lessons, beginner-friendly challenges, and real-world projects. Perfect for aspiring Salesforce developers."
        />
        <meta
          name="keywords"
          content="learn apex coding, salesforce development tutorial, salesforce for beginners, apex programming practice, salesforce developer training, learn salesforce development, hands on apex, interactive apex lessons"
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Learn Salesforce Development | Interactive Apex Coding Practice"
        />
        <meta
          property="og:description"
          content="Get hands-on experience with Salesforce development through free interactive lessons and challenges. Perfect for beginners!"
        />
        <meta property="og:url" content="https://www.campapex.org" />
        <link rel="canonical" href="https://www.campapex.org/" />
      </Helmet>
      <Hero />
      <EmbeddedForm
        formId="670935d377"
        subheading="Stay up to date with latest courses, updates, and announcements"
      />
      <FeatureShowcase />
      <InteractiveDemo />

      {/* <FeedbackAnimation />
      <div className="p-4 sm:p-6 my-8 sm:my-12 text-center border border-white mx-4 sm:mx-auto max-w-4xl">
        <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4 text-primary">
          This must cost a fortune!
        </h3>
        <p className="text-sm sm:text-base text-gray-300 mb-4 px-4 sm:px-8 md:px-16 lg:px-32">
          Nope! Where you live or your financial situation shouldn't stop you
          from learning Salesforce Development. Camp Apex is 100% free. You can{" "}
          <a
            href="https://buymeacoffee.com/campapexdotorg"
            className="text-yellow-400 hover:underline font-semibold"
            target="_blank"
            rel="noopener noreferrer"
          >
            donate
          </a>{" "}
          to help cover server costs if you'd like.
        </p>
      </div>*/}
      <Testimonials />
      {/* <FeaturedLinks /> */}
      <CallToAction navigate={navigate} />
      {/* <AdventNotice /> */}
    </div>
  );
};

const Hero = () => (
  <section className="relative min-h-screen flex items-center justify-center overflow-hidden px-4 pt-16 pb-8 md:py-0">
    <div className="absolute inset-0 z-0">
      <ParticleBackground />
    </div>
    <div className="z-10 text-center">
      <h1 className="text-3xl md:text-4xl font-bold mb-4 animate-fade-down">
        Interactive Lessons and Challenges <br /> to Learn{" "}
        <span className="text-blue-300">Salesforce</span> Development
      </h1>
      <p className="text-base md:text-lg mb-8 mt-4 animate-fade-up delay-200">
        Learn by doing with hands-on lessons paired with challenges. <br />
        Aspiring Developers and Admins welcome.
      </p>
      <div className="animate-fade-scale delay-400">
        <Link
          to="/auth"
          className="btn btn-primary btn-lg px-32 md:px-64 whitespace-nowrap"
        >
          <span className="flex items-center justify-center text-xs md:text-lg">
            Get Started - It's (literally) FREE
            <FaArrowRight className="ml-2" />
          </span>
        </Link>
      </div>
    </div>
  </section>
);

const ParticleBackground = () => {
  useEffect(() => {
    const canvas = document.getElementById("particle-canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const particles = [];
    const particleCount = 100;

    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        dx: (Math.random() - 0.5) * 0.5,
        dy: (Math.random() - 0.5) * 0.5,
      });
    }

    function animate() {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < particleCount; i++) {
        let p = particles[i];
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.radius, 0, Math.PI * 2, false);
        ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
        ctx.fill();

        p.x += p.dx;
        p.y += p.dy;

        if (p.x < 0 || p.x > canvas.width) p.dx = -p.dx;
        if (p.y < 0 || p.y > canvas.height) p.dy = -p.dy;
      }
    }

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <canvas id="particle-canvas" className="absolute inset-0" />;
};

const FeatureShowcase = () => {
  const features = [
    {
      icon: FaCode,
      title: "Interactive Lessons",
      description:
        "Apex, SOQL, & OOP. Bite-sized lessons covering the fundamentals. Each paired with a challenge to solve",
      link: "/courses",
    },
    {
      icon: FaTools,
      title: "Real-world Projects",
      description:
        "Clicks or Code? Build custom Salesforce applications from scratch using Flow or Apex",
      link: "/projects",
    },
    {
      icon: GiTrophy,
      title: "Competitions",
      description:
        "Compete head to head in monthly competitions for a shot at the leaderboard",
      link: "/compete",
    },
    // {
    //   icon: GiMountainClimbing,
    //   title: "Coming Soon!",
    //   description:
    //     "Leetcode for Admins. Practice building Flows, Validation Rules, sObjects, Permission Sets, and more.",
    //   link: "/practice/admin",
    // },

    {
      icon: MdQueryStats,
      title: "SOQL Challenges (Coming Soon!)",
      description: "Practice building SOQL commands to retrieve data",
      link: "",
    },
    {
      icon: GiMountainClimbing,
      title: "Developer Challenges (Coming Soon!)",
      description: "Practice Apex, Triggers, and more",
      link: "",
    },
    {
      icon: PiFlowArrowBold,
      title: "Flow Into Apex",
      description:
        "Build your coding skills through practical challenges that test your ability to convert Flows into Apex.",
      link: "/practice/flow-into-apex",
    },
    {
      icon: GiGingerbreadMan,
      title: "Advent of Salesforce",
      description:
        "25 days of festive Salesforce challenges to celebrate the holdays. Live from December 1st - 25th",
      link: "/advent",
    },
  ];

  return (
    <section className="py-16 md:py-20 bg-gradient-to-b from-zinc-900 to-zinc-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12 text-primary">
          A little something for everyone
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 md:gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

const FeatureCard = ({ icon: Icon, title, description, link }) => (
  <Link to={link} className="block">
    <div className="bg-zinc-800 p-6 rounded-lg shadow-lg border border-primary hover:bg-zinc-700 transition-colors duration-300 h-full">
      <Icon className="text-4xl mx-auto mb-4 text-primary" />
      <h3 className="text-xl font-semibold mb-2 text-center">{title}</h3>
      <p className="text-center text-gray-300">{description}</p>
    </div>
  </Link>
);

const InteractiveDemo = () => {
  const [code] = React.useState(`public class NameBuilder {
  private String firstName = '';
  private String lastName = '';
  private String fullName = '';

  public void setFirstName(String firstName) {
    this.firstName = firstName;
    updateFullName();
  }

  public void setLastName(String lastName) {
    this.lastName = lastName;
    updateFullName();
  }

  private void updateFullName() {
    this.fullName = this.firstName + ' ' + this.lastName;
  }

  public String getFullName() {
    return this.fullName;
  }
}`);

  return (
    <section className="py-16 md:py-20 bg-zinc-800">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12 text-primary">
          Read a little, practice a lot
        </h2>
        <div className="flex flex-col md:flex-row items-start justify-between ">
          <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8 md:pt-24">
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4">
                Learn and Practice
              </h3>
              <p className="text-gray-300 mb-4">
                After each lesson, apply what you've learned with built-in
                challenges.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-4 pt-9">
                Hands-on and Self-Paced
              </h3>
              <p className="text-gray-300 mb-4">
                Go at a speed that works for you. Camp Apex ensures you grasp
                each concept before moving on.
              </p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-4 pt-9">
                Real Code and Real Experience
              </h3>
              <p className="text-gray-300 mb-4">
                Don't just watch - do! Write real code, solve real problems, and
                gain practical experience.
              </p>
            </div>
          </div>

          <div className="md:w-1/2 h-[400px] md:h-[600px] bg-zinc-900 p-4 rounded-lg border border-primary w-full">
            <Editor
              defaultLanguage="apex"
              theme="vs-dark"
              value={code}
              options={{
                minimap: { enabled: false },
                fontSize: 14,
                readOnly: false,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const Testimonials = () => {
  return (
    <section className="py-16 md:py-20 bg-zinc-900">
      <div className="container mx-auto px-4">
        {/* <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12 text-primary">
          What Campers Are Saying
        </h2> */}
        <div className="w-full">
          <iframe
            id="wall-of-love-2yNE8N"
            src="https://senja.io/p/camp-apex/2yNE8N?hideNavigation=true&embed=true"
            title="Wall of Love"
            frameBorder="0"
            scrolling="no"
            className="w-full"
            style={{ width: "1px", minWidth: "100%" }}
          />
        </div>
      </div>
    </section>
  );
};

const CallToAction = ({ navigate }) => (
  <section className="py-16 md:py-20 bg-gradient-to-b from-zinc-900 to-primary">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-3xl md:text-4xl font-bold mb-4">Do Your Big One</h2>
      <p className="mb-8 text-lg md:text-xl">
        Join Camp Apex today and transform your skills with hands-on,
        interactive learning.
      </p>
      <button
        className="btn btn-secondary btn-lg px-32 md:px-64 whitespace-nowrap"
        onClick={() => navigate("/auth")}
      >
        <span className="flex items-center justify-center text-xs md:text-lg">
          Start Your Journey
          <FaArrowRight className="ml-2" />
        </span>
      </button>
    </div>
  </section>
);

export default HomePage;
